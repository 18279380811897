<template>
  <content-layout
    v-if="$route.name === 'vendor-home'"
    fluid
    :toolbar-enabled="isTaxDocumentExpired"
    class="pattern"
  >
    <template #toolbar v-if="isTaxDocumentExpired">
      <div
        class="text-body-1 font-weight-semibold d-flex align-center"
        style="column-gap: 10px"
      >
        <span>{{ $t('vendor.taxDocsExpiredMessage') }}</span>
        <a @click="onVendorUploadDocsClicked" href="javascript:void(0)">{{
          $t('vendor.taxDocsUploadButtonText')
        }}</a>
      </div>
    </template>
    <vendor-docs-update-dialog
      v-model="vendorDocsUpdateDialog.show"
      v-bind="vendorDocsUpdateDialog"
      @close="vendorDocsUpdateDialog.show = false"
      @submit="onVendorDocsUpdateSubmit"
    />
    <div
      class="px-6"
      :class="{
        'full-height': !isActive
      }"
    >
      <v-progress-circular
        indeterminate
        style="position: fixed; top: 50%; left: 50%"
        v-if="loading"
        color="primary"
        :size="60"
      />
      <template v-else-if="isActive" v-for="groupItem in groups">
        <div class="text-h5 my-6" :key="groupItem.title">
          {{ groupItem.title }}
        </div>
        <v-row :key="groupItem.id">
          <v-col v-for="item in groupItem.cards" :key="item.title" cols="auto">
            <cz-dashboard-card
              :image-src="item.image"
              :title="item.title"
              :subtitle="item.subtitle"
              :count="item.count()"
              @click="$router.push(item.to)"
            />
          </v-col>
        </v-row>
      </template>
      <div
        class="d-flex full-width justify-center align-center"
        style="height: calc(100vh - 200px)"
        v-else
      >
        <v-card
          :height="500"
          :width="500"
          hover
          rounded="lg"
          outlined
          class="d-flex flex-column justify-center align-center"
          clickable
          :disabled="hasBlocked"
          @click="onVendorClicked"
        >
          <div v-if="!vendor" class="text-h4 text-pre-wrap text-center">
            {{ $t('vendor.home.newVendorText') }}
          </div>
          <div v-else class="text-pre-wrap text-center">
            <div class="font-weight-semibold text-h4">
              {{ vendor.businessName }}
            </div>
            <div class="text-h5">
              {{ vendorStatusTitle }}
            </div>
          </div>
          <img :height="300" :src="vendorStatusImage" />
        </v-card>
      </div>
    </div>
  </content-layout>
  <router-view v-else />
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import VendorDocsUpdateDialog from '../components/VendorDocsUpdateDialog.vue';
import newVendor from '@/assets/illustrations/new-vendor.svg';
import editVendor from '@/assets/illustrations/edit-vendor.svg';
import pending from '@/assets/illustrations/pending.svg';
import returned from '@/assets/illustrations/returned.svg';
import rejected from '@/assets/illustrations/vendors-rejected.svg';
import approved from '@/assets/illustrations/vendor-approval.svg';
import closedOrdersImage from '@/assets/illustrations/orders-closed.svg';
import vendorActiveOrders from '@/assets/illustrations/orders-active.svg';
import pendingImage from '@/assets/illustrations/pending-approval.svg';
import vendorInvoiceUpload from '@/assets/illustrations/upload.svg';
import inProgressImage from '@/assets/illustrations/in-progress.svg';
import { CzDashboardCard, CzButton } from '@/components';
import { uploadFiles, updateVendor } from '@/core/api';
import { isPast, parseISO, endOfDay, parse } from 'date-fns';
import { mapActions } from 'vuex';
export default {
  name: 'VendorHomePage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzButton,
    VendorDocsUpdateDialog,
    CzDashboardCard
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapFields('auth', ['account', 'accountId']),
    ...mapFields('vendor', [
      'vendor',
      'ordersCount',
      'submissionsCount',
      'vendors'
    ]),
    vendorStatusTitle() {
      if (!this.id) {
        return this.$t('vendor.home.newVendorText');
      } else {
        if (this.vendor?.status === 'draft') {
          return this.$t('vendor.home.editVendorText');
        } else if (this.vendor?.status === 'pending_approval') {
          return this.$t('vendor.home.pendingVendorText');
        } else if (this.vendor?.status === 'returned') {
          return this.$t('vendor.home.returnedVendorText');
        } else if (this.vendor?.status === 'blocked') {
          return this.$t('vendor.home.rejectedVendorText');
        }
      }
    },
    isTaxDocumentExpired() {
      if (!this.vendor) {
        return false;
      }

      return (
        isPast(
          endOfDay(
            parseISO(this.vendor?.accountingBooksCertificationExpirationDate)
          )
        ) && this.vendor.status === 'active'
      );
    },
    vendorStatusImage() {
      if (!this.vendor) {
        return newVendor;
      } else {
        if (this.vendor?.status === 'draft') {
          return editVendor;
        } else if (this.isPending) {
          return pending;
        } else if (this.hasReturned) {
          return returned;
        } else if (this.hasBlocked) {
          return rejected;
        }
      }
    },
    hasBlocked() {
      return this.vendor?.status === 'blocked';
    },
    hasReturned() {
      return this.vendor?.status === 'returned';
    },
    isPending() {
      return this.vendor?.status === 'pending_approval';
    },
    isActive() {
      return (
        this.vendor?.status === 'active' ||
        this.vendor?.status === 'pending_docs_update'
      );
    }
  },
  data() {
    return {
      newVendor,
      editVendor,
      loading: false,
      groups: [
        {
          id: 'orders',
          title: this.$t('vendor.home.ordersTitle'),
          cards: [
            {
              title: this.$t('vendor.home.activeOrdersTitle'),
              image: vendorActiveOrders,
              subtitle: this.$t('vendor.home.activeOrdersInfo'),
              count: () => {
                if (this.ordersCount) {
                  return this.$t('customer.orders.ordersCount', [
                    this.ordersCount.open
                  ]);
                } else {
                  return null;
                }
              },
              to: {
                name: 'vendor-orders'
              }
            },
            {
              title: this.$t('vendor.home.closedOrdersTitle'),
              image: closedOrdersImage,
              subtitle: this.$t('vendor.home.closedOrdersInfo'),
              count: () => {
                if (this.ordersCount) {
                  return this.$t('customer.orders.ordersCount', [
                    this.ordersCount.closed
                  ]);
                } else {
                  return null;
                }
              },
              to: {
                name: 'vendor-orders',
                params: {
                  selectedTab: 1
                }
              }
            }
          ]
        },
        {
          id: 'submissions',
          title: this.$t('vendor.home.ordersSubmissionsTitle'),
          cards: [
            {
              title: this.$t('vendor.orderSubmissions.inProgressSubmissions'),
              image: inProgressImage,
              subtitle: this.$t(
                'vendor.orderSubmissions.inProgressSubmissionsInfo'
              ),
              count: () => {
                if (this.submissionsCount) {
                  return this.$t('customer.orders.orderSubmissionsCount', [
                    this.submissionsCount.draft
                  ]);
                } else {
                  return null;
                }
              },
              to: {
                name: 'vendor-submissions',
                params: {
                  selectedTab: 0
                }
              }
            },
            {
              title: this.$t('vendor.orderSubmissions.returnedSubmissions'),
              image: returned,
              subtitle: this.$t(
                'vendor.orderSubmissions.returnedSubmissionsInfo'
              ),
              count: () => {
                if (this.submissionsCount) {
                  return this.$t('customer.orders.orderSubmissionsCount', [
                    this.submissionsCount.returned
                  ]);
                } else {
                  return null;
                }
              },
              to: {
                name: 'vendor-submissions',
                params: {
                  selectedTab: 1
                }
              }
            },
            {
              title: this.$t('vendor.orderSubmissions.pendingSubmissions'),
              image: pendingImage,
              subtitle: this.$t(
                'vendor.orderSubmissions.pendingSubmissionsInfo'
              ),
              count: () => {
                if (this.submissionsCount) {
                  return this.$t('customer.orders.orderSubmissionsCount', [
                    this.submissionsCount.pending
                  ]);
                } else {
                  return null;
                }
              },
              to: {
                name: 'vendor-submissions',
                params: {
                  selectedTab: 2
                }
              }
            },
            {
              title: this.$t('vendor.orderSubmissions.approvedSubmissions'),
              image: approved,
              subtitle: this.$t(
                'vendor.orderSubmissions.approvedSubmissionsInfo'
              ),
              count: () => 0,
              to: {
                name: 'vendor-submissions',
                params: {
                  selectedTab: 3
                }
              }
            },
            {
              title: this.$t('vendor.orderSubmissions.rejectedSubmissions'),
              image: rejected,
              subtitle: this.$t(
                'vendor.orderSubmissions.rejectedSubmissionsInfo'
              ),
              count: () => 0,
              to: {
                name: 'vendor-submissions',
                params: {
                  selectedTab: 4
                }
              }
            }
          ]
        }
      ],
      vendorDocsUpdateDialog: {
        show: false,
        loading: false
      }
    };
  },
  methods: {
    ...mapActions('vendor', [
      'countOrders',
      'countSubmissions',
      'loadVendors',
      'setSelectedVendor'
    ]),
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    async loadData() {
      try {
        this.loading = true;
        // load account vendors
        // if no vendors exists yet, then
        // we need to redirect the user to vendor on-boarding for
        // create a new account vendor
        await this.loadVendors();
        const exists = await this.setSelectedVendor(this.id);
        if (!this.id || !exists) {
          this.$router.replace({
            name: 'vendor-home',
            params: {
              id: this.vendor?._id || 'new'
            }
          });
        }
      } finally {
        this.loading = false;
      }
    },
    onVendorClicked() {
      this.$router.push({
        name: 'vendor-onboarding',
        params: {
          id: this.vendor?._id || 'new'
        }
      });
    },
    async countOrdersAndSubmissions() {
      if (!this.isActive) {
        return;
      }

      try {
        this.loading = true;
        await this.countOrders();
        await this.countSubmissions();
      } finally {
        this.loading = false;
      }
    },
    onVendorUploadDocsClicked() {
      this.vendorDocsUpdateDialog.show = true;
    },
    async onVendorDocsUpdateSubmit(form) {
      try {
        this.vendorDocsUpdateDialog.loading = true;

        const [taxDeductionAuthorizationRes, accountingBooksCertificationRes] =
          await uploadFiles(
            [form.taxDeductionAuthorization, form.accountingBooksCertification],
            this.accountId
          );

        this.vendor = await updateVendor(
          this.vendor._id,
          {
            accountingBooksCertificationFileId:
              accountingBooksCertificationRes._id,
            taxDeductionAuthorizationFileId: taxDeductionAuthorizationRes._id,
            accountingBooksCertificationExpirationDate: parse(
              form.taxDeductionAuthorizationExpDate,
              'dd/MM/yyyy',
              new Date()
            ),
            status: 'pending_docs_update'
          },
          {
            $populate: ['files']
          }
        );

        this.vendorDocsUpdateDialog.show = false;

        this.showSuccessToastMessage(this.$t('vendor.taxDocsUpdated'));
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
      }
    }
  },
  watch: {
    id: {
      handler(value) {
        this.setSelectedVendor(value);
      }
    },
    vendor: {
      immediate: true,
      handler(value) {
        if (value) {
          this.countOrdersAndSubmissions();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
